import cx from 'classnames';
import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import React, { PureComponent, useEffect, useMemo, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import FieldContainer from '../components/FieldContainer';
import Icon from '../components/Icon';
import './PhoneNumberField.scss';
import { useRequest } from 'ahooks';
import validationService, { useConfirmationCode } from '../services/validation.service';
import InputBlocker from '../components/InputBlocker';
import { StringField } from './index';
import { isFieldLocked } from '../components/utils';
import i18n from '../translations';

function hasOneOfPrefix(phoneNumber, prefixes) {
  if (!prefixes || prefixes.length === 0) return false;
  const withoutPlus = phoneNumber.replace('+', '').replace(/^00/, '');
  return !!prefixes.find((prefix) => startsWith(withoutPlus, prefix));
}

const CodeCheckIcon = ({ state }) => {
  switch (state) {
    case 'success':
      return <Icon className={`icon--${state}`} name="check" />;
    case 'error':
      return <Icon className={`icon--${state}`} name="times" />;
    case 'loading':
      return <Icon className={`icon--${state}`} name="refresh" spin />;
    default:
      return null;
  }
};

const SendButton = ({ phoneNumber, strings, disabled }) => {
  const { data, loading, run } = useRequest(() => validationService.sendCode(phoneNumber), { manual: true });
  const btn = get(strings, ['confirm', 'btn'], 'Envoyer le code');

  function handleSend(e) {
    e.preventDefault();
    e.stopPropagation();

    run();
  }

  return (
    <button type="button" className="af-form-button" onClick={handleSend} disabled={disabled || loading}>
      <span style={{ opacity: loading ? 0 : undefined }}>{btn}</span>
      {loading && (
        <div className="af-form-button__icon__container">
          <Icon name="refresh" spin />
        </div>
      )}
    </button>
  );
};

const ConfirmBySMS = ({ name, phoneNumber, strings, config }) => {
  const { codeLength = 6, prefixExceptions } = config;
  const [value, setValue] = useState('');
  const { loading, confirmationCode, error, checkCode } = useConfirmationCode();
  const confirmationRequired = get(
    strings,
    ['confirm', 'description'],
    'Une confirmation par SMS est nécessaire pour valider votre numéro de téléphone et votre inscription',
  );
  const placeholder = get(strings, ['confirm', 'placeholder'], 'Code');

  useEffect(() => {
    if (value.length === codeLength) {
      // Time to try query...
      checkCode(phoneNumber, value);
    }
  }, [value]);

  const isSuccess = confirmationCode;
  const confirmationState = useMemo(() => {
    if (isSuccess) return 'success';
    if (value.length !== codeLength) return 'error';
    if (loading) return 'loading';
    return 'error';
  }, [isSuccess, value, loading]);

  if (hasOneOfPrefix(phoneNumber, prefixExceptions)) {
    return null; // No need to check
  }

  return (
    <div className="PhoneInput__confirmation">
      <p>{confirmationRequired}</p>
      <div>
        <SendButton phoneNumber={phoneNumber} strings={strings} disabled={isSuccess} />
        <input
          type="string"
          className="af-string-field"
          value={value}
          disabled={isSuccess}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
        />
        <input name={`${name}Confirmation`} value={confirmationCode} required style={{ width: 1, height: 1, opacity: 0, border: 0, padding: 0 }} />
        <CodeCheckIcon state={confirmationState} />
      </div>
      {!isSuccess && error && <p style={{ color: '#d30f00' }}>{error}</p>}
    </div>
  );
};

export default class PhoneNumberField extends PureComponent {
  static defaultProps = {
    value: '',
    required: false,
  };

  render() {
    const {
      id,
      placeholder,
      label,
      mode,
      name,
      value,
      onChange,
      required,
      disabled,
      confirmBySMS = {},
      defaultCountry = 'FR',
      defaultValue,
      hideCountrySelector,
      strings = {},
      summary,
      locked,
    } = this.props;
    const { invalidPhoneNumber = i18n.t('errors.INVALID_PHONE_NUMBER') } = strings;
    if (disabled && !value) return null; // Hide disabled field
    const isLocked = isFieldLocked(name, locked);
    // Unbox phone array
    const stringValue = (Array.isArray(value) ? value.join('').replace('FR0', '+33') : value) || defaultValue;
    const isValid = !stringValue || stringValue.length === 0 || isValidPhoneNumber(stringValue);
    const error = (!isValid && stringValue && stringValue !== '+') || undefined;

    if (mode === 'summary') {
      if (summary?.hidden) {
        return null;
      }
      return <StringField label={summary?.label || label} mode="summary" value={stringValue} />;
    }

    return (
      <FieldContainer {...this.props}>
        <PhoneInput
          id={id}
          defaultCountry={defaultCountry}
          displayInitialValueAsLocalNumber={false}
          className={cx('af-phoneNumber-field', { invalid: error, hideCountrySelector })}
          name={name + 'Input'}
          required={required}
          disabled={disabled || isLocked}
          placeholder={placeholder}
          value={stringValue}
          onChange={(phone) => onChange(name, phone)}
          error={error}
        />
        {/* Duplicate with plain input to store the full full number instead of the local one */}
        {isValid && (
          <>
            <input name={name} value={stringValue} readOnly style={{ display: 'none' }} />
            {value && confirmBySMS && confirmBySMS.enabled && (
              <ConfirmBySMS name={name} phoneNumber={stringValue} strings={strings} config={confirmBySMS} />
            )}
          </>
        )}
        {value && value !== '+' && !isValid && <div style={{ color: '#d30f00' }}>{invalidPhoneNumber}</div>}
        {(!isValid || (required && !value)) && <InputBlocker name={name} />}
      </FieldContainer>
    );
  }
}
