/* eslint-disable @typescript-eslint/no-explicit-any */
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CandidateUser, RoomingAccommodation } from '../rooming.types';
import { computeInitialRoom, computeLevelTypes, findAccommodationAndLevel, getUserFullname, LevelType, RoomRequest } from '../utils';
import RoomingButton from './Button';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { default: Select } = require('react-select');

enum RoomState {
  NONE,
  CREATING,
  VALIDATED,
}

function ensureStringArray(value: any): string[] {
  if (!value) return [];
  if (Array.isArray(value)) return value.map((v) => v?.value || v);
  return [value?.value || value];
}

function userOption(user: CandidateUser): { value: string; label: string } {
  if (!user) return { value: 'unknown', label: 'unknown' };
  return { value: user._id, label: getUserFullname(user) };
}

function useAvailableUsers(users: CandidateUser[]) {
  const me = window.__DATA__.data;
  return useMemo(() => {
    if (!users) return [];
    return orderBy(users.filter((u) => !u.hasValidRoom && u._id !== me._id).map(userOption), 'label');
  }, [users, me]);
}

type RoomingCreateProps = {
  value: any;
  onChange: any;
  accommodations?: RoomingAccommodation[];
  users: CandidateUser[];
  levels: string[];
  design?: Record<string, any>;
};

function getDefaultRoomState(value: any) {
  if (!isEmpty(value)) return RoomState.VALIDATED;
  return RoomState.NONE;
}

const RoomingCreate: FC<RoomingCreateProps> = (props): JSX.Element => {
  const { accommodations = [], value, onChange, users, levels, design = {} } = props;
  const { position = 'horizontal' } = design;

  const { t } = useTranslation();
  const [state, setState] = useState(getDefaultRoomState(value));
  const [room, setRoom] = useState<RoomRequest>(() => computeInitialRoom(accommodations ?? [], value, levels));
  const usersById = useMemo(() => keyBy(users, '_id'), [users]);
  const options = useAvailableUsers(users);

  if (state === RoomState.NONE) {
    return <RoomingButton className="af-form-button--create" content={t('rooming.ask-for-room')} onClick={() => setState(RoomState.CREATING)} />;
  }

  const { accommodation: selectedAccommodation, accommodationLevel: selectedLevel } = findAccommodationAndLevel(accommodations ?? [], room);
  const selectCount = selectedLevel?.maxUsers ? selectedLevel?.maxUsers - 1 : 0;

  function validateRoom() {
    setState(RoomState.VALIDATED);
    onChange(room);
  }

  if (state === RoomState.VALIDATED && selectedLevel) {
    if (selectedLevel?.maxUsers === 1) {
      return (
        <div className="af-card">
          <h3>{t('rooming.you-have-requested', { category: selectedLevel.name })}</h3>
        </div>
      );
    }
    return (
      <div className="af-card">
        <h3>{t('rooming.you-have-invited-persons', { count: selectCount, category: selectedLevel?.name })}</h3>
        <Select
          className={cx('multiselect', 'field')}
          value={room.users?.map((userId) => userOption(usersById[userId])) || []}
          required
          onChange={handleUserChange}
          options={options}
          isMulti={selectCount > 1}
          isSearchable
          isDisabled
          max={selectCount}
          min={selectCount}
        />
      </div>
    );
  }

  const mergedTypes = computeLevelTypes(accommodations, levels);

  // target hotels having the selected type
  const accommodationTypeIds = mergedTypes.find((t) => t.type === room.accommodationLevelType)?.levels.map((l) => l.accommodationId) || [];
  const accommodationList =
    accommodations
      ?.filter((acc) => accommodationTypeIds.includes(acc._id))
      ?.map((acc) => {
        const { title, _id: accommodationId, levels } = acc;
        const level = levels?.find((l) => l.type === room.accommodationLevelType);
        const { remainingRooms = 0 } = level || {};
        return {
          label: `${title} (${t('rooming.remaining-rooms', { count: remainingRooms })})`,
          title,
          value: accommodationId,
          levels: acc.levels,
          isDisabled: remainingRooms === 0,
        };
      }) || [];

  function handleUserChange(v: string | string[] | null) {
    const newUsers = ensureStringArray(v);
    if (newUsers.length > selectCount) {
      return; // Can't select more
    }
    setRoom({ ...room, users: newUsers || [] });
  }

  const handleTypeChange = (type: LevelType) => {
    const accommodationLevelType = type.type;
    const { users = [] } = room;
    let accommodationId: string | undefined = undefined;
    let accommodationLevelId: string | undefined = undefined;

    if (type.levels.length === 1) {
      accommodationLevelId = type.levels[0]._id;
    }
    // Auto-set accommodationId ?
    const accommodationIds = uniq(type.levels.map((l) => l.accommodationId));
    if (accommodationIds.length === 1) {
      accommodationId = accommodationIds[0];
    }

    const accommodationLevel = accommodations.find((acc) => acc._id === accommodationId)?.levels?.find((l) => l._id === accommodationLevelId);
    const maxUsersToSelect = accommodationLevel?.maxUsers ? accommodationLevel?.maxUsers - 1 : 0;
    const mustFlushUsers = users.length > 0 && users.length !== maxUsersToSelect;

    setRoom({ ...room, accommodationLevelType, accommodationId, accommodationLevelId, users: mustFlushUsers ? [] : users });
  };

  const handleAccommodationChange = ({ value }: { title: string; value: string }) => {
    const accommodation = accommodations?.find((acc) => acc._id === value);
    const accommodationLevel = accommodation?.levels?.find((l) => l.type === room.accommodationLevelType);
    const { _id: accommodationLevelId } = accommodationLevel || {};
    setRoom({ ...room, accommodationId: value, accommodationLevelId, users: [] });
  };

  return (
    <div className="af-card af-card--ask-for-room">
      <h3>{t('rooming.ask-for-room')}</h3>
      <p className="text--room-category-selection">{t('rooming.room-category-selection')}</p>
      <div className="af-field-container af-field-container--RadioField">
        <div style={{ display: 'flex', flexDirection: position === 'vertical' ? 'column' : 'row' }}>
          {mergedTypes?.map((type) => (
            <label
              key={type.type}
              className={cx(room.accommodationLevelType === type.type && 'is-active', type.remainingRooms <= 0 && 'is-disabled')}
            >
              <input
                type="radio"
                className={cx('af-radio-field', type.remainingRooms <= 0 && 'is-disabled')}
                name="category"
                required
                checked={type.type === room.accommodationLevelType}
                disabled={type.remainingRooms <= 0}
                onChange={() => handleTypeChange(type)}
              />
              <span>{type.name}</span>
            </label>
          ))}
        </div>
      </div>
      {!!room.accommodationLevelType && accommodations?.length > 1 && (
        <div>
          <p className="text--room-category-selection">{t('rooming.hotel-selection')}</p>
          <Select
            className="hotel-select"
            value={{ label: selectedAccommodation?.title, value: selectedAccommodation?._id }}
            required
            isClearable
            isMulti={false}
            onChange={handleAccommodationChange}
            options={accommodationList}
            isSearchable
            menuPosition="absolute"
          />
        </div>
      )}
      {!!selectedLevel && !!selectCount && (
        <>
          <p className="text--select-your-roommates">{t('rooming.select-your-roommates', { count: selectCount })}</p>
          <Select
            className={cx('multiselect', 'field')}
            value={room.users?.map((userId) => userOption(usersById[userId])) || []}
            required
            onChange={handleUserChange}
            options={options}
            isMulti={selectCount > 1}
            isSearchable
            max={selectCount}
            min={selectCount}
          />
        </>
      )}
      {state === RoomState.CREATING && (
        <>
          <button className="af-form-button" type="button" style={{ background: '#777' }} onClick={() => setState(RoomState.NONE)}>
            {t('btn.cancel')}
          </button>
          <button className="af-form-button" type="button" disabled={(room.users ?? [])?.length < selectCount} onClick={validateRoom}>
            {t('rooming.validate')}
          </button>
        </>
      )}
    </div>
  );
};

export default RoomingCreate;
