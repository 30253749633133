import memoizee from 'memoizee';
import { CarbonFootprintTransport, ComputedCarbonFootprint } from '../carbon-footprint.types';

const distanceMatrixService = window.google ? new google.maps.DistanceMatrixService() : undefined;

const carCo2kgByKm = {
  'essence': 0.223,
  'diesel': 0.212,
  'electrique': 0.103,
  'hybride diesel': 0.217,
  'hybride essence': 0.232,
  'hydrogene': 0.081,
};

// FROM https://agirpourlatransition.ademe.fr/particuliers/bureau/deplacements/calculer-emissions-carbone-trajets
const CO2KgByKm = {
  'walk': 0,
  'bike': 0,
  'carpooling': 0,
  'electric-bike': 0.0107, // https://impactco2.fr/transport/veloelectrique
  'electric-scooter': 0.0107, // https://impactco2.fr/transport/veloelectrique
  'ter': 0.02769, // https://impactco2.fr/transport/ter
  'tgv': 0.00293, // https://impactco2.fr/transport/tgv
  'metro': 0.00444, // https://impactco2.fr/transport/metro
  'bus': 0.1127, // https://impactco2.fr/transport/busthermique
  'plane-short': 0.25858, // https://impactco2.fr/transport/avioncourtcourrier
  'plane-medium': 0.18756, // https://impactco2.fr/transport/avionmoyencourrier
  'plane-long': 0.15196, // https://impactco2.fr/transport/avionlongcourrier
};

const planeCO2Kg = (distanceKm: number) => {
  if (distanceKm <= 1000) return distanceKm * CO2KgByKm['plane-short'];
  if (distanceKm <= 3500) return distanceKm * CO2KgByKm['plane-medium'];
  return distanceKm * CO2KgByKm['plane-long'];
};

const cachedGetDistanceMatrix = distanceMatrixService
  ? memoizee(distanceMatrixService.getDistanceMatrix, {
      promise: true,
      normalizer: (args) => JSON.stringify(args[0]),
    })
  : undefined;

/**
 * Try to compute carbon footprint, return undefined if not possible yet
 * @param transport
 * @returns
 */
export async function computeTransportCO2Kg(transport: CarbonFootprintTransport): Promise<ComputedCarbonFootprint | undefined> {
  const { to, from, distanceKm: manualDistanceKm, transportType } = transport;
  const useManualDistance = typeof manualDistanceKm === 'number';
  if (!(useManualDistance || (to && from))) return undefined;

  // Direct distance (used as an approximation for the actual distance)
  const distanceKm =
    useManualDistance || !window.google?.maps
      ? manualDistanceKm || 0
      : window.google.maps.geometry.spherical.computeDistanceBetween(from!.location, to!.location) / 1000;
  switch (transportType) {
    case 'walk':
    case 'bike':
    case 'electric-bike':
    case 'electric-scooter':
    case 'tgv':
    case 'ter':
    case 'bus':
    case 'metro':
      return { distanceKm, CO2Kg: distanceKm * CO2KgByKm[transportType] };
    case 'plane':
      return { distanceKm, CO2Kg: planeCO2Kg(distanceKm) };
    case 'car':
      if (!transport.engineType) return undefined;

      if (useManualDistance) {
        return { distanceKm, CO2Kg: distanceKm * carCo2kgByKm[transport.engineType] };
      }

      // Missing info ?
      if (!cachedGetDistanceMatrix) return undefined;
      try {
        const res = await cachedGetDistanceMatrix({
          origins: [from!.location],
          destinations: [to!.location],
          travelMode: google.maps.TravelMode.DRIVING,
          // transitOptions: TransitOptions,
          // drivingOptions: DrivingOptions,
          unitSystem: google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false,
        });
        if (!res.rows.length) return undefined;
        const distanceKm = res.rows[0].elements[0].distance.value / 1000;
        return { distanceKm, CO2Kg: distanceKm * carCo2kgByKm[transport.engineType] };
        // return { distanceKm };
      } catch (e) {
        console.error('computeTransportCO2Kg', e);
        return undefined;
      }
    case 'carpooling':
    case 'none': {
      return { distanceKm: 0, CO2Kg: 0 };
    }
    default: {
      console.log('unhandled transport type', transportType);
      return undefined;
    }
  }
}

export function roundValue(value: number): number {
  if (value >= 100) return Math.round(value);
  if (value >= 10) return Math.round(value * 10) / 10;
  if (value >= 1) return Math.round(value * 100) / 100;
  if (value >= 0.1) return Math.round(value * 1000) / 1000;
  return Math.round(value * 10000) / 10000;
}

function isValidTransport(item: CarbonFootprintTransport): boolean {
  const { transportType, computed } = item;
  if (!transportType) return false;
  return transportType === 'none' || transportType === 'carpooling' || !!computed;
}

export function hasRequiredTransportData(data: any): boolean {
  const transport = data.private?.transport;
  if (!transport) return false;

  const { items } = transport;
  if (!items?.length) return false;

  return items.every((item: CarbonFootprintTransport) => isValidTransport(item));
}
